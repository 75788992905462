<template>
  <AuthWrapper>
    <p class="auth-notice">Already have an account? <router-link to="/auth/login">Sign In</router-link></p>
    <div class="auth-contents">
      <a-form name="register" :model="formState" @finish="handleSubmit" layout="vertical">
        <sdHeading as="h3"> Sign Up to <span class="color-secondary">Admin</span> </sdHeading>
        <a-form-item label="First Name" name="f_name">
          <a-input v-model:value="formState.f_name" placeholder="First name" />
        </a-form-item>
        <a-form-item name="l_name" label="Last Name">
          <a-input v-model:value="formState.l_name" placeholder="Last Name" />
        </a-form-item>
        <a-form-item name="u_name" label="User Id">
          <a-input v-model:value="formState.u_name" placeholder="User Id" />
        </a-form-item>
        <a-form-item name="t_name" label="Team Name">
          <a-input v-model:value="formState.t_name" placeholder="Team Name" />
        </a-form-item>
        <a-form-item name="email" label="Email Address">
          <a-input type="email" v-model:value="formState.email" placeholder="name@example.com" />
        </a-form-item>
        <a-form-item label="Password" name="password">
          <a-input type="password" v-model:value="formState.password" placeholder="Password" />
        </a-form-item>
        <div class="auth-form-action">
          <a-checkbox @change="onChange">
            Creating an account means you’re okay with our Terms of Service and Privacy Policy
          </a-checkbox>
        </div>
        <a-form-item>
          <!-- <button class="btn-create"  htmlType="submit" type="primary" size="large">Create Account</button> -->
          <button class="btn-create vue-btn" style="font-size: 15px; background-color: #5C69B3  !important;border: solid 1px #5C69B3;" @click="accountadd" htmlType="submit" type="primary" size="large"> Create Account </button>
        </a-form-item>
        <!-- <p class="form-divider">
          <span>Or</span>
        </p>
        <ul class="social-login signin-social">
          <li>
            <a class="google-signup" href="#">
              <img :src="require('@/static/img/google.png')" alt="" />
              <span>Sign up with Google</span>
            </a>
          </li>
          <li>
            <a class="facebook-sign" href="#">
              <FacebookOutlined />
            </a>
          </li>
          <li>
            <a class="twitter-sign" href="#">
              <TwitterOutlined />
            </a>
          </li>
        </ul> -->
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons-vue';
import { computed, reactive, ref, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { AuthWrapper } from './style';
import { useRouter } from 'vue-router';
import { message, Notification } from "ant-design-vue";

const SignUp = defineComponent({
  name: 'SignUp',
  components: { FacebookOutlined, TwitterOutlined, AuthWrapper },
  

  setup() {
    const { state, dispatch } = useStore();
    var isLoading = computed(() => state.auth.loading);
    const router = useRouter();
    const values = ref(null);
    const checked = ref(null);
    var message = ref(null);
    const handleSubmit = (value) => {
      values.value = value;
      // isLoading = true;
      console.log(formState);
      
        // if (passwordField.meta.valid && emailField.meta.valid) {
          dispatch('signup', values.value).then(
            ( ) => {
              Notification["success"]({
                message: "Success!",
                description: "User Created Successfully!",
              });
              isLoading = false;
              state.auth.login = true;
              router.push('/');
            },
            (error) => {
              console.log(error);
              
              var errors = (JSON.parse(error.data));
              if(errors.f_name){
                Notification["error"]({
                  message: "Error!",
                  description: errors.f_name[0],
                });
              }
              if(errors.l_name){
                Notification["error"]({
                  message: "Error!",
                  description: errors.l_name[0],
                });
              }
              if(errors.email){
                Notification["error"]({
                  message: "Error!",
                  description: errors.email[0],
                });
              }
              if(errors.password){
                Notification["error"]({
                  message: "Error!",
                  description: errors.password[0],
                });
              }
              // errors.forEach((value, index) => {
              //     // arr.push(value);
              //     console.log(value);
              //     console.log(index);
              // });
              if (typeof error != 'string') {
                // console.log(error.data.error.toString());
                isLoading = false;
                // message.value = error.data.error.toString();
                message.value = 'Wrong email id or password';
              } else {
                isLoading = false;
                // message.value = 'Error Comunicating To Server';
                message.value = 'Invalid Credentials';
              }

              // console.log(error.data.error.toString());
              // isLoading = false;
              // // message.value = error.data.error.toString();
              // message.value = 'Wrong email id or password';
            },
          );
        // }
      // console.log(values);
    };

    const onChange = (check) => {
      checked.value = check;
    };

    const accountadd = () => {
      alert('anil');
    };

    const formState = reactive({
      name: '',
      username: '',
      u_name: '',
      t_name: '',
      email: '',
      password: '',
    });
    return {
      onChange,
      handleSubmit,
      formState,
    };
  },
});

export default SignUp;
</script>
